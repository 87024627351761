import React, { Component } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import {Link} from "react-router-dom"
import CommonMethods from "../common/CommonMethods"
import Global from "../common/Global"
import style from "../../style.module.css"

export default class Navi extends Component {

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
    };
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }


  render() {
    return (
      <div>
          <Navbar color="dark" dark expand="md" className={style.navBar}>
            <NavbarBrand><Link style={{textDecoration: 'none', fontSize: 24, color: 'dodgerblue'}} to="/">Ezberleriz</Link></NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="mr-auto" navbar>
                {                    
                    Global.CATEGORY_NAMES.map( (categoryName, index) => (
                        <NavItem key={index}>
                          <NavLink>
                            <Link className={style.navLink} to={"/"+CommonMethods.getCategoryRouteName(index)} 
                                  onClick={() => CommonMethods.setCategory(index, this.props)}>{categoryName}
                            </Link>
                          </NavLink>
                        </NavItem>
                    )) 
                }
              </Nav>
            </Collapse>
          </Navbar>
      </div>
    );
  }
}
