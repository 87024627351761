import React, { Component } from 'react'
import { BsTextRight } from 'react-icons/bs';
import {Container, Input, Button} from 'reactstrap'
import {Link} from "react-router-dom"
import CommonMethods from '../common/CommonMethods';
import Content from '../Models/Content';

export default class AddYourContent extends Component {

    state = {
        inputContentName: '',
        inputContentAuthor: '',
        inputContentText: ''
    };


    updateInputValue(inputValue, input_area_index){
        switch(input_area_index){
            case 0: return this.setState({inputContentName: inputValue});
            case 1: return this.setState({inputContentAuthor: inputValue});
            case 2: return this.setState({inputContentText: inputValue});
            default: return;
        }
    }

    addContentToUserContents = (newUserContent) => {
        let user_contents;

        if(localStorage.getItem("user_contents") === null){
            user_contents = [];
        } else{
            user_contents = JSON.parse(localStorage.getItem("user_contents"));
        }

        user_contents.push(newUserContent)

        localStorage.setItem("user_contents", JSON.stringify(user_contents));
    }

    onAddContentButton(){

        if(this.isInputInvalid()){ // Girdi yoksa
            alert("İçeriğin ismini ve kendisini boş bırakmayınız.");
        } else{
            let newUserContent = new Content(this.state.inputContentName, this.state.inputContentAuthor, this.state.inputContentText);

            this.addContentToUserContents(newUserContent)
             
            this.props.setCurrentUserLearningActivity({
                currentContent: newUserContent 
            })

            this.route_name = "sizin_ekledikleriniz";
        }
        
    }

    isInputInvalid(){
        return this.state.inputContentName.trim() === "" || this.state.inputContentText.trim() === ""
    }

    render(){
        return (
            <Container>
                Kendin Ekle
                <Input style={{marginBottom:10, marginTop: 10}} placeholder="İçeriğin ismi" 
                onChange={event => this.updateInputValue(event.target.value, 0)}/>
                <Input style={{marginBottom:10, marginTop: 10}} placeholder="İçeriğin yazarı (varsa)" 
                onChange={event => this.updateInputValue(event.target.value, 1)}/>
                <Input style={{marginBottom:10, marginTop: 10, height: 300}} type="textarea" placeholder="İçeriği buraya yapıştırınız." 
                onChange={event => this.updateInputValue(event.target.value, 2)}/>
                <Link to={
                    this.isInputInvalid() 
                    ? "/kendin_ekle"
                    : "/"+"sizin_ekledikleriniz"+"/"+CommonMethods.makeSlug(this.state.inputContentName)
                    }>
                    <div className="text-center"><Button color="primary" style={{width: 200}} onClick={() => this.onAddContentButton()}>
                        Ekle
                    </Button>
                    </div>
                </Link>
            </Container>
        ) 
    }
}