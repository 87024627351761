import React, { Component, useState } from "react";
import { Container, Row, Col, Progress, CardImg, Button } from "reactstrap";
import { Switch, Route, Link, useParams } from "react-router-dom";

import style from "../../style.module.css";
import ResultPage from "./ResultPage"
import CommonMethodsForExercises from "./CommonMethodsForExercises";
import { BsThreeDotsVertical } from "react-icons/bs";
import ChoosePractice from "./ChoosePractice";
import ChooseDifficultyLevel from "./ChooseDifficultyLevel";
import PracticeTypes from "./PracticeTypes"
import {FaPlay, FaPause} from "react-icons/fa"

export default class Practice extends Component {

  state = {
    practice: 0,
    difficultyLevel: 0
  }

  state = {
    game_stats: {
      remaining_count: 0,
      hit_count: 0,
      fail_count: 0,
      hint_count: 0,
      health: 100,
      score: 0,
    },
    game_state: 0,
    option_states: [0, 0, 0, 0], // 0 default durum, -1 yanlış olduğunda.
  };

  constructor(props) {
    super(props);

    // const [currentUserLearningActivity, setCurrentUserLearningActivity] = useState({...props.currentUserLearningActivity})
    this.timer = 0;
    this.pause = false;
    this.startTimer = this.startTimer.bind(this);
    this.countDown = this.countDown.bind(this);
    this.content_route_name = this.props.currentUserLearningActivity.content_route_name;
  }

  resetPractice = () => {
    this.setState({
      practice: 0,
      difficultyLevel: 0,
      game_stats: {
        remaining_count: 0,
        hit_count: 0,
        fail_count: 0,
        hint_count: 0,
        health: 100,
        score: 0,
      },
      game_state: 0,
      option_states: [0, 0, 0, 0]
    })
  }
  
  startTimer() {
    if (this.timer === 0 && this.state.game_stats.health > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }

  countDown() {
    // Remove one from health, set state so a RE-RENDER happens.
    if (this.state.game_state === 1) {
      let health = this.state.game_stats.health - 1;
      this.setState({
        game_stats: {
          ...this.state.game_stats,
          health: health,
        },
      });

      // Check if we're below zero. If below zero, finish the game.
      if (health <= 0) {
        this.finishExercise();
        clearInterval(this.timer);
      }
    }
  }

  getNewScoreString = (new_score) => {
    let score_zeros = ""; // 5 haneye tamamlasın.
    let number_of_digits = 0;

    let temp_score = new_score;

    while (temp_score >= 1) {
      temp_score /= 10;
      number_of_digits++;
    }

    for (let i = 0; i < 5 - number_of_digits; i++) {
      score_zeros += "0";
    }

    return score_zeros + (new_score === 0 ? "" : new_score);
  };

  onRightAnswer = (score_change, hint_count) => {
    if (this.state.game_state === 1) {
      let new_health = this.state.game_stats.health + 3;

      this.setState({
        game_stats: {
          ...this.state.game_stats,
          hit_count: this.state.game_stats.hit_count + 1,
          remaining_count: this.state.game_stats.remaining_count - 1,
          score: this.state.game_stats.score + score_change,
          health: Math.min(new_health, 100),
          hint_count,
        },
      });
    }
  };

  onGameStart = (remaining_count, hint_count) => {
    this.setState({
      game_stats: {
        remaining_count,
        hint_count,
        hit_count: 0,
        fail_count: 0,
        health: 100,
        score: 0,
      },
      game_state: 1,
    });

    this.startTimer();
  };

  onWrongAnswer = (health_change, option_index) => {
    if (this.state.game_state === 1) {
      let options_states = this.createOptionStatesArray(option_index);

      this.setState({
        game_stats: {
          ...this.state.game_stats,
          fail_count: this.state.game_stats.fail_count + 1,
          health: this.state.game_stats.health + health_change,
        },
        option_states: options_states,
      });

      // After a while
      setTimeout(() => {
        this.setState({
          option_states: [0, 0, 0, 0],
        });
      }, 1200);
    }
  };

  createOptionStatesArray(option_index) {
    let options_states = [];
    for (let i = 0; i < 4; i++) {
      if (i === option_index) options_states.push(-1);
      else options_states.push(0);
    }
    return options_states;
  }

  finishExercise = () => {
    this.setState({
      game_state: 3, //finished anlamında 3, duraklatıldığında 2, başlamadıysa 0, oyun içerisinde 1
    });
  };

  onPauseResumeButtonClick = () => {
      if(this.pause) this.resumeExercise();
      else this.pauseExercise();
  }

  pauseExercise = () => {
    this.pause = true;
    this.setState({
      game_state: 2,
    });
  };

  resumeExercise = () => {
    this.pause = false;
    this.setState({
      game_state: 1,
    });
  };

  onChooseDifficultyLevel = (diffLevel) => {
    this.setState({difficultyLevel: diffLevel})
  }
  onChoosePractice = (practice_type) => {
    this.setState({practice: practice_type})
  }

  renderResultPage = () => (
    <div className={style.center}>
        <ResultPage />
    </div>
  )

  getContentRouteName = () => {
    return this.content_route_name === ""
      ? ""
      : "/" + this.content_route_name;
  };

  render() {
    let iconStyles = { color: "white", fontSize: "4em" };
    return (
      <div className={style.practice_container}>
        <Container>
          <Row>
            <Col xs="2" className={style.container_with_centered_items}>
                <div className={style.prToolbar}>
                  Skor <br />
                  <div
                    style={{ fontSize: 25, padding: 5, margin: 5 }} 
                    className={style.toolbarStatsBox}
                  >
                    {this.getNewScoreString(this.state.game_stats.score)}
                  </div>
                </div>
            </Col>
            <Col xs="10">
              <Row>
                  <Col xs="9">
                  <div className={style.prToolbar}>
                    Boşluk{" "}
                    <div className={style.toolbarStatsBox}>
                      {this.state.game_stats.remaining_count}
                    </div>
                  </div>
                  <div className={style.prToolbar}>
                    Doğru{" "}
                    <div className={style.toolbarStatsBox}>
                      {this.state.game_stats.hit_count}
                    </div>
                  </div>
                  <div className={style.prToolbar}>
                    Hata{" "}
                    <div className={style.toolbarStatsBox}>
                      {this.state.game_stats.fail_count}
                    </div>
                  </div>
                  <div className={style.prToolbar}>
                    İpucu{" "}
                    <div className={style.toolbarStatsBox}>
                      {this.state.game_stats.hint_count}
                    </div>
                  </div>
                  </Col>
                  <Col xs="3" className={style.container_with_centered_items}>
                    <div className={style.pause_button} onClick={() => this.onPauseResumeButtonClick()}>
                      {this.state.game_state === 2 ? <FaPlay style={iconStyles}/> : <FaPause style={iconStyles}/>}
                    </div>
                  </Col>
              </Row>
              <Row>
                <Progress
                  style={{
                    marginTop: 10,
                    marginRight: 10,
                    marginBottom: 10,
                    height: 20,
                    bgcolor: "#FF3030",
                  }}
                  bar
                  color="danger"
                  value={this.state.game_stats.health}
                />
              </Row>
            </Col>
          </Row>
        </Container>
          
        <Switch>
          <Route exact
            path={
              "/" +
              this.props.route_name +
              this.getContentRouteName() +
              "/:verse_id_from_url/practice" 
            }
            render={(props) => (
              <ChoosePractice
                {...props}
                route_name={this.props.route_name}
                content_route_name={this.getContentRouteName()}
                onChoosePractice={this.onChoosePractice}
              />
            )}
          ></Route>
          <Route exact
            path={
              "/" +
              this.props.route_name +
              this.getContentRouteName() +
              "/:verse_id_from_url/practice/:practice_type" 
            }
            render={(props) => (
              <ChooseDifficultyLevel
                {...props}
                route_name={this.props.route_name}
                content_route_name={this.getContentRouteName()}
                onChooseDifficultyLevel={this.onChooseDifficultyLevel}
              />
            )}
          ></Route>
          <Route exact
            path={
              "/" +
              this.props.route_name +
              this.getContentRouteName() +
              "/:verse_id_from_url/practice/:practice_type/:difLevel" 
            }
            render={(props) => (
              <PracticeTypes
                {...props}
                route_name={this.props.route_name}
                content_route_name={this.getContentRouteName()}
                currentUserLearningActivity={this.props.currentUserLearningActivity}
                game_stats={this.state.game_stats}
                game_state={this.state.game_state}
                setRemainingCount={this.setRemainingCount}
                onRightAnswer={this.onRightAnswer}
                onWrongAnswer={this.onWrongAnswer}
                finishExercise={this.finishExercise}
                onGameStart={this.onGameStart}
                onHintUse={this.onHintUse}
                option_states={this.state.option_states}
                resetPractice={this.resetPractice}
              />
            )}
          ></Route>

        </Switch>
        
        </div>
    );
  }
}
