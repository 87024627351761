import React, { Component } from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import style from "../../style.module.css";

export default class ChooseDifficultyLevel extends Component {

    constructor(props){
        super(props);
    }

    renderDifficultyLevelChoiceButton = (level, color, text) => (
        <div className={style.container_with_centered_items}>
            <Link
            onClick={() => this.props.onChooseDifficultyLevel(level)}
            to={
                "/" +
                this.props.route_name +
                this.props.content_route_name  +
                "/"+parseInt(this.props.match.params.verse_id_from_url)+
                "/practice/"+this.props.match.params.practice_type+"/"+level
            }
            >
                <div>
                    <Button className={style.practice_choice_button} color={color} size="lg">{text}</Button>
                </div>
            </Link>
        </div>

    )

    render = () => (
        <div>
            {this.renderDifficultyLevelChoiceButton(1, "success", "Alıştırma")}
            {this.renderDifficultyLevelChoiceButton(2, "primary", "Başlangıç")}
            {this.renderDifficultyLevelChoiceButton(3, "warning", "Orta Seviye")}
            {this.renderDifficultyLevelChoiceButton(4, "info",    "Zor")}
            {this.renderDifficultyLevelChoiceButton(5, "danger", "Ezberimde")}
       </div>
    )

}