import { registerVersion } from "firebase";


export default class RemoveMethods{
    //The word replaced with @ won't be seen by user.

    static removeForEasyMode(str, rowNum){
        let rnd = []
        let i = 0, j = 0
        // console.log(i)

        for (i = 0; i < rowNum; i++) {
            if(str[i].length === 0) rnd[i] = -1;
                rnd[i] = parseInt((Math.random() * str[i].length));

            if(rnd[i] !== -1)
                for (j = 0; j < str[i][rnd[i]].length; j++) {
                   str[i][rnd[i]] = str[i][rnd[i]].replace(str[i][rnd[i]].charAt(j), '@'); 
                }
        }
        // console.log(rnd)
        // console.log(str)

        return rnd;
    }

    static removeForMediumMode(str, rowNum) {
        let rnd = []
        //int[] rnd = new int[rowNum * 2];
        let k = 0, i = 0, j = 0;
        for (i = 0;  i < rowNum && k < rowNum * 2; i++, k+=2) {

            if(str[i].length === 0){

            }
            else if(str[i].length === 1){
                rnd[k] = 0;
                rnd[k+1] = -1; //Not enough words
            }
            else if(str[i].length === 2){
                rnd[k] = 0;
                rnd[k+1] = 1;
            }
            else if(str[i].length === 3){
                rnd[k] = parseInt(Math.random()*2); //0 veya 1
                rnd[k+1] = 2; //2 olması lazım ama....
            }
            else if(str[i].length === 4){
                rnd[k] = parseInt(Math.random()*2); //0 veya 1
                rnd[k+1] = parseInt(Math.random()*2) + 2; //2 veya 3
            }
            else{ //5ten itibaren bu tamam 0 1 2 ve 3 sonrası
                rnd[k] = parseInt(Math.random() * 3);  //0 1 2
                rnd[k + 1] = parseInt(Math.random() * (str[i].length - 3)) + 3;  //3... boyut-1
            }

            if(rnd[k] !== -1)
            for (j = 0; j < str[i][rnd[k]].length; j++) {
                str[i][rnd[k]] = str[i][rnd[k]].replace(str[i][rnd[k]].charAt(j), '@');  //gösterilmeyeceğe @ koyuyorum.
            }

            if(rnd[k+1] !== -1)
                for (j = 0; j < str[i][rnd[k+1]].length; j++) {
                str[i][rnd[k+1]] = str[i][rnd[k+1]].replace(str[i][rnd[k+1]].charAt(j), '@');  //gösterilmeyeceğe @ koyuyorum.
            }
        }


        // console.log(rnd)

        return rnd;
    }

    static setRnd(rnd, k, rnd1, rnd2, rnd3){
        rnd[k]     = rnd1;
        rnd[k + 1] = rnd2;
        rnd[k + 2] = rnd3;
    }

    static removeForHardMode(str, rowNum) {  //Bilgiyi tutmam lazım ki doğru mu yanlış mı gösterebileyim. O da ayrı bir dizide
        //dursun.
        let rnd = []
        let k = 0, i = 0, j = 0;
        for (i = 0; i < rowNum && k < rowNum * 3; i++, k += 3) { //Bunu içiçe döngü ile de yapabilirdim, neyse böyle yaptık.
            //Aynı random'u döndürdüğünde? döndürürse de tekini kapatsın sorun değil. Böyle kalmadı sanırım :D 

            //BURADA 1 ELEMANDAN 5-6 ELEMANA KADAR SAYI ÜRETMELİ. SIKINTI ÇIKAR. setRnd metodu yapabilirim. setRnd(... , ..., ...)
            if(str[i].length === 0){
                
            }
            else if(str[i].length === 1){
                this.setRnd(rnd, k, 0, -1, -1);
            }
            else if(str[i].length === 2){
                this.setRnd(rnd, k, 0, 1, -1);
            }
            else if(str[i].length === 3){
                this.setRnd(rnd, k, 0, 1, 2);
            }
            else if(str[i].length === 4){
                this.setRnd(rnd, k, parseInt(Math.random()*2), 2, 3);
            }
            else if(str[i].length === 5){
                this.setRnd(rnd, k, parseInt(Math.random()*2), parseInt(Math.random()*2)+2, 4);
            }
            else{
                rnd[k] = parseInt(Math.random() * 2);  //0 veya 1
                rnd[k + 1] = parseInt(Math.random() * 2) + 2;  //2 veya 3
                rnd[k + 2] = parseInt(Math.random() * (str[i].length-4)) + 4; //4 veya sonrası
            }

            if(rnd[k] !== -1)
            for (j = 0; j < str[i][rnd[k]].length; j++) {
                str[i][rnd[k]] = str[i][rnd[k]].replace(str[i][rnd[k]].charAt(j), '@');  //gösterilmeyeceğe * koyuyorum.
            }
            if(rnd[k+1] !== -1)
            for (j = 0; j < str[i][rnd[k+1]].length; j++) {
                str[i][rnd[k+1]] = str[i][rnd[k+1]].replace(str[i][rnd[k+1]].charAt(j), '@');  //gösterilmeyeceğe * koyuyorum.
            }
            if(rnd[k+2] !== -1)
                for (j = 0; j < str[i][rnd[k+2]].length; j++) {
                str[i][rnd[k+2]] = str[i][rnd[k+2]].replace(str[i][rnd[k+2]].charAt(j), '@');  //gösterilmeyeceğe * koyuyorum.
            }

        }

        console.log(rnd)

        return rnd;
    }

    static removeForLevel4(str, rowNumber) {  //Bilgiyi tutmam lazım ki doğru mu yanlış mı gösterebileyim. O da ayrı bir dizide
        let i = 0, j = 0;
        // int rnd[] = new int[strlengtha-rowNumber];
        let rnd = [];

        let k = 0, t = 0;

        for (i = 0; i < rowNumber; i++) {
            for (t = 1; t < str[i].length; k++, t++) {
                rnd[k] = t;  //Heeeeeeeeeeeeeeeeeee k böyle olunca 0-7 arası kalmıyor. Mevzu şu rnd[k]'daki k uçmalı = sonrası no.

                for (j = 0; j < str[i][rnd[k]].length; j++) {
                    str[i][rnd[k]] = str[i][rnd[k]].replace(str[i][rnd[k]].charAt(j), '@'); //burada gösterilmek istenmeyenin yerine * koysam boşlukla uğraşmasam
                }
            }
        }

        console.log(rnd)

        return rnd;
    }





    static removeForVeryHardMode(str, rowNumber) {
        let i = 0, j = 0;
        //Loglamalar başlasın.

        let rnd = []
        // int rnd[] = new int[strlengtha];

        let k = 0, t = 0;

        for (i = 0; i < rowNumber; i++) {
            for (t = 0; t < str[i].length; k++, t++) {
                rnd[k] = t;  //Heeeeeeeeeeeeeeeeeee k böyle olunca 0-7 arası kalmıyor. Mevzu şu rnd[k]'daki k uçmalı = sonrası no.

                for (j = 0; j < str[i][rnd[k]].length; j++) {
                    str[i][rnd[k]] = str[i][rnd[k]].replace(str[i][rnd[k]].charAt(j), '@'); //burada gösterilmek istenmeyenin yerine * koysam boşlukla uğraşmasam
                }
            }
        }

        console.log(rnd)

        return rnd;
    }














}




/*
public class RemoveMethods {

    public String replaceWithAsterisk(String str){ //The word replaced with asterisks won't be seen by user.
        for (int j = 0; j < str.length(); j++) {
            str = str.replace(str.charAt(j), '@');
        }

        return str;
    }

    public int[] removeForEasyMode(String str[][], int rowNum) { //One word from each row.
        int[] rnd = new int[rowNum];

        for (int i = 0; i < rowNum; i++) {
            if(str[i].length == 0) rnd[i] = -1;
            rnd[i] = (int) (Math.random() * str[i].length);

            if(rnd[i] != -1)
                for (int j = 0; j < str[i][rnd[i]].length(); j++) {
                   str[i][rnd[i]] = str[i][rnd[i]].replace(str[i][rnd[i]].charAt(j), '@');  //gösterilmeyeceğe * koyuyorum.
                }
        }

        return rnd;
    }

    public int[] removeForMediumMode(String str[][], int rowNum) {
        int[] rnd = new int[rowNum * 2];
        int k = 0;
        for (int i = 0;  i < rowNum && k < rowNum * 2; i++, k+=2) {

            if(str[i].length == 0){

            }
            else if(str[i].length == 1){
                rnd[k] = 0;
                rnd[k+1] = -1; //Not enough words
            }
            else if(str[i].length == 2){
                rnd[k] = 0;
                rnd[k+1] = 1;
            }
            else if(str[i].length == 3){
                rnd[k] = (int) Math.random()*2; //0 veya 1
                rnd[k+1] = 2; //2 olması lazım ama....
            }
            else if(str[i].length == 4){
                rnd[k] = (int)Math.random()*2; //0 veya 1
                rnd[k+1] = (int)Math.random()*2 + 2; //2 veya 3
            }
            else{ //5ten itibaren bu tamam 0 1 2 ve 3 sonrası
                rnd[k] = (int) (Math.random() * 3);  //0 1 2
                rnd[k + 1] = (int) (Math.random() * (str[i].length - 3)) + 3;  //3... boyut-1
            }

            if(rnd[k] != -1)
            for (int j = 0; j < str[i][rnd[k]].length(); j++) {
                str[i][rnd[k]] = str[i][rnd[k]].replace(str[i][rnd[k]].charAt(j), '@');  //gösterilmeyeceğe * koyuyorum.
            }

            if(rnd[k+1] != -1)
                for (int j = 0; j < str[i][rnd[k+1]].length(); j++) {
                str[i][rnd[k+1]] = str[i][rnd[k+1]].replace(str[i][rnd[k+1]].charAt(j), '@');  //gösterilmeyeceğe * koyuyorum.
            }
        }

        return rnd;
    }

    public void setRnd(int rnd[], int k, int rnd1, int rnd2, int rnd3){
        rnd[k]     = rnd1;
        rnd[k + 1] = rnd2;
        rnd[k + 2] = rnd3;
    }

    public int[] removeForHardMode(String str[][], int rowNum) {  //Bilgiyi tutmam lazım ki doğru mu yanlış mı gösterebileyim. O da ayrı bir dizide
        //dursun.
        int[] rnd = new int[rowNum * 3];
        int k = 0;
        for (int i = 0; i < rowNum && k < rowNum * 3; i++, k += 3) { //Bunu içiçe döngü ile de yapabilirdim, neyse böyle yaptık.
            //Aynı random'u döndürdüğünde? döndürürse de tekini kapatsın sorun değil.

            //BURADA 1 ELEMANDAN 5-6 ELEMANA KADAR SAYI ÜRETMELİ. SIKINTI ÇIKAR. setRnd metodu yapabilirim. setRnd(... , ..., ...)
            if(str[i].length == 0){

            }
            else if(str[i].length == 1){
                setRnd(rnd, k, 0, -1, -1);
            }
            else if(str[i].length == 2){
                setRnd(rnd, k, 0, 1, -1);
            }
            else if(str[i].length == 3){
                setRnd(rnd, k, 0, 1, 2);
            }
            else if(str[i].length == 4){
                setRnd(rnd, k, (int)Math.random()*2, 2, 3);
            }
            else if(str[i].length == 5){
                setRnd(rnd, k, (int)Math.random()*2, (int)Math.random()*2+2, 4);
            }
            else{
                rnd[k] = (int) (Math.random() * 2);  //0 veya 1
                rnd[k + 1] = (int) (Math.random() * 2) + 2;  //2 veya 3
                rnd[k + 2] = (int) (Math.random() * (str[i].length-4)) + 4; //4 veya sonrası
            }

            if(rnd[k] != -1)
            for (int j = 0; j < str[i][rnd[k]].length(); j++) {
                str[i][rnd[k]] = str[i][rnd[k]].replace(str[i][rnd[k]].charAt(j), '@');  //gösterilmeyeceğe * koyuyorum.
            }
            if(rnd[k+1] != -1)
            for (int j = 0; j < str[i][rnd[k+1]].length(); j++) {
                str[i][rnd[k+1]] = str[i][rnd[k+1]].replace(str[i][rnd[k+1]].charAt(j), '@');  //gösterilmeyeceğe * koyuyorum.
            }
            if(rnd[k+2] != -1)
                for (int j = 0; j < str[i][rnd[k+2]].length(); j++) {
                str[i][rnd[k+2]] = str[i][rnd[k+2]].replace(str[i][rnd[k+2]].charAt(j), '@');  //gösterilmeyeceğe * koyuyorum.
            }

        }

        return rnd;
    }

    public int[] removeForLevel4(String str[][], int rowNumber) {  //Bilgiyi tutmam lazım ki doğru mu yanlış mı gösterebileyim. O da ayrı bir dizide
        int strlengtha = 0;
        for(int i=0; i<rowNumber; i++){
            for(int j=0; j<str[i].length; j++){
                strlengtha++;
            }
        }
        //Loglamalar başlasın.

        int rnd[] = new int[strlengtha-rowNumber];

        int k = 0, y = 0, t = 0;

        for (int i = 0; i < rowNumber; i++) {
            for (t=1; t<str[i].length; k++, t++) {
                rnd[k] = t;  //Heeeeeeeeeeeeeeeeeee k böyle olunca 0-7 arası kalmıyor. Mevzu şu rnd[k]'daki k uçmalı = sonrası no.

                for (int j = 0; j < str[i][rnd[k]].length(); j++) {
                    str[i][rnd[k]] = str[i][rnd[k]].replace(str[i][rnd[k]].charAt(j), '@'); //burada gösterilmek istenmeyenin yerine * koysam boşlukla uğraşmasam
                }
            }
        }

        return rnd;
    }



    public int[] removeForVeryHardMode(String[][] str, int rowNumber) {
        int strlengtha = 0;
        for(int i=0; i<rowNumber; i++){
            for(int j=0; j<str[i].length; j++){
                strlengtha++;
            }
        }
        //Loglamalar başlasın.

        int rnd[] = new int[strlengtha];

        int k = 0, y = 0, t = 0;

        for (int i = 0; i < rowNumber; i++) {
            for (t=0; t<str[i].length; k++, t++) {
                rnd[k] = t;  //Heeeeeeeeeeeeeeeeeee k böyle olunca 0-7 arası kalmıyor. Mevzu şu rnd[k]'daki k uçmalı = sonrası no.

                for (int j = 0; j < str[i][rnd[k]].length(); j++) {
                    str[i][rnd[k]] = str[i][rnd[k]].replace(str[i][rnd[k]].charAt(j), '@'); //burada gösterilmek istenmeyenin yerine * koysam boşlukla uğraşmasam
                }
            }
        }

        return rnd;
    }



}
*/