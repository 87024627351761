import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/root/App';
import * as serviceWorker from './serviceWorker';
import * as firebase from 'firebase'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'alertifyjs/build/css/alertify.min.css'
import {BrowserRouter} from "react-router-dom"  

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
var firebaseConfig = {
  apiKey: "AIzaSyBZu99nW1_sGZnsx0n5vkEGlZPfPG0DLtc",
  authDomain: "deneme-7a08b.firebaseapp.com",
  databaseURL: "https://deneme-7a08b.firebaseio.com",
  projectId: "deneme-7a08b",
  storageBucket: "deneme-7a08b.appspot.com",
  messagingSenderId: "875265106943",
  appId: "1:875265106943:web:03b51f858c9d2ca022fdca",
  measurementId: "G-E1ZBL43JF2"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root'); 

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename={baseUrl}><App /></BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
