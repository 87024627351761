// TODO: sizin ekledikleriniz (Son Aktiviteleriniz olmalı) ve kendin ekle kısmını kaldırıyorum şimdilik.
//this.CATEGORY_ROUTE_NAMES = ["sizin_ekledikleriniz", "istiklal_marsi", "dualar", "siirler", "marslar", "kendin_ekle"]
this.CATEGORY_ROUTE_NAMES = ["istiklal_marsi", "dualar", "siirler", "marslar"]

//this.CATEGORY_NAMES = ["Sizin Ekledikleriniz", "İstiklal Marşı", "Dualar", "Şiirler", "Marşlar", "Kendin Ekle"]
this.CATEGORY_NAMES = ["İstiklal Marşı", "Dualar", "Şiirler", "Marşlar"]



/*this.SON_AKTIVITELER_ID = 0;
this.ISTIKLAL_MARSI_ID = 1;
this.DUALAR_ID = 2;
this.SIIRLER_ID = 3;
this.MARSLAR_ID = 4;
this.KENDIN_EKLE_ID = 5;*/

this.SON_AKTIVITELER_ID = -999; // İnaktif
this.ISTIKLAL_MARSI_ID = 0;
this.DUALAR_ID = 1;
this.SIIRLER_ID = 2;
this.MARSLAR_ID = 3;
this.KENDIN_EKLE_ID = -999; // İnaktif
