import React, { Component } from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import {Link, Switch, Route} from "react-router-dom"
import CommonMethods from "../common/CommonMethods";
import Content from "../Models/Content"
import style from "../../style.module.css"
import SearchField from "react-search-field";
import Global from "../common/Global"


export default class ContentList extends Component {
  
  state = {
    searchText: "",
  }

  constructor(props){
    super(props)
    this.route_name = this.props.match.params.currentCategory;
  }

  addToUserContents(contentObject){
        let user_contents;

        if(localStorage.getItem("user_contents") === null){
            user_contents = [];
        } else{
            user_contents = JSON.parse(localStorage.getItem("user_contents"));
        }

        if(!this.alreadyExistsInUserContents(contentObject, user_contents)){
            user_contents.push(contentObject)
            localStorage.setItem("user_contents", JSON.stringify(user_contents));  
        }

  }

  alreadyExistsInUserContents = (contentObject, user_contents) => {
    let exists = false;
    user_contents.forEach((item, index) => {
      if(exists === true) return;

      if(item.name === contentObject.name && item.author === contentObject.author){
        exists = true;
        return true;   // Already exists in user contents, not add it.
      }
    })

    return exists;
  }

  getPlaceholder(){
      switch(this.props.currentCategoryId){
        case 0: return "Ezberlemekte olduklarınız içinde ara"
        case 1: return ""
        case 2: return "Dualar içinde ara"
        case 3: return "Şiir veya yazar ara"
        case 4: return "Marşlar içinde ara"
        default: return ""
      }
  }

  getItems = () => {
     let searchText = this.state.searchText;
      
     if(searchText.length > 0){
        const filteredContents = this.props.items.filter(content => {
            return content.name.toLowerCase().includes(this.state.searchText.toLowerCase())
          })
        return filteredContents;
      } 
          
      return this.props.items
  }

  onChangeSearch = (value, event) => {
    this.setState({
      searchText: value
    })
  }

  renderContentList = () => {
    return (
      <div>
        {
        // Şu an default olan section_id 4 dualar siirler marslar diye koyulsun,     icerik adı izmir-marsi
        // TODO stackoverflow'daki abc-def-asd yapan paketi alacağım. section_id/icerik_adi seklinde bir sayfaya gidecek. Şimdilik izmir-marsi
        // Oradan da section_id/icerik_adi/read   ve  section_id/icerik_adi/practice olur,
        // Practice'ten de  section_id/icerik_adi/practice/multiple-choice  şeklinde olur, güzel.
        }

        <div className={style.contentListHeaderContainer}>
            <h2 className={style.contentListHeaderText}>{this.props.currentCategory}</h2>
            <div className={style.contentListSearchField}>
              <SearchField
                placeholder={this.getPlaceholder()}
                onChange={this.onChangeSearch}
              />
            </div>
        </div>
        <ListGroup className={style.contentListContainer} horizontal>
            {this.getItems().map(item => (
                <Link className={style.contentListItem} style={{textDecoration: 'none'}} 
                to={"/"+this.route_name+"/"+CommonMethods.makeSlug(item.name)}>
                <ListGroupItem key={item.id} 
                    onClick={()=>{                          
                      let contentObject = new Content(item.name, item.author, item.content_text, item.content_type);

                      if(this.props.currentCategoryId !== 0){
                        this.addToUserContents(contentObject)   // Bir içeriğe tıkladığında o içeriği user contents'e ekliyorum.
                      }

                      this.content_route_name = CommonMethods.makeSlug(item.name)
                      this.props.setCurrentUserLearningActivity({
                        currentContent: contentObject,
                        content_route_name: this.content_route_name
                      })
                    }}>
                        {item.name}
                </ListGroupItem>
                </Link>
            ))}
        </ListGroup>
      </div>
    )
  }

  render() {
    return (
      <div>
        {this.renderContentList()}
      </div>
    );
  }
}
