import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Read from "../read/Read";
import Practice from "../practice/Practice";
import NotFound from "../common/NotFound";

export default class ContentRoute extends Component {
  render = () => {
    {this.route_name = this.props.route_name}
    {console.log("here", this.route_name)}

    return (<Switch>
              <Route
        path={[
          "/istiklal_marsi/:verse_id_from_url/practice",
          "/" + this.props.route_name + "/:content_route_name/:verse_id_from_url/practice",
        ]}
        render={(props) => (
          <Practice
            {...props}
            route_name={this.props.route_name}
            currentUserLearningActivity={this.props.currentUserLearningActivity}
            setCurrentUserLearningActivity={
              this.props.setCurrentUserLearningActivity
            }
          />
        )}
      ></Route>
      <Route
        exact
        path={[
          "/istiklal_marsi" + "/:verse_id_from_url",
          "/istiklal_marsi",
          "/" + this.route_name + "/:content_name" + "/:verse_id_from_url",
          "/" + this.route_name + "/:content_name"
        ]}
        render={(props) => (
          <Read
            {...props}
            currentCategory={this.props.currentCategory}
            currentCategoryId={this.props.currentCategoryId}
            route_name={this.props.route_name}
            currentUserLearningActivity={this.props.currentUserLearningActivity}
            setCurrentUserLearningActivity={
              this.props.setCurrentUserLearningActivity
            }
          />
        )}
      ></Route>

      {/* (this.content_route_name === "" ? "":  "/"+this.content_route_name)+*/}

       <Route path="/" component={NotFound}></Route>
    </Switch>
  )}
}
