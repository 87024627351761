
import Content from "../Models/Content"

export default class istiklal_marsi{

    constructor(){
        this.contentName = "İstiklal Marşı"
        this.author = "Mehmet Akif Ersoy"
        this.content = "Korkma, sönmez bu şafaklarda yüzen al sancak;\n"+
        "Sönmeden yurdumun üstünde tüten en son ocak.\n"+
        "O benim milletimin yıldızıdır, parlayacak;\n"+
        "O benimdir, o benim milletimindir ancak.\n"+
        "\n"+
        "Çatma, kurban olayım çehreni ey nazlı hilâl!\n"+
        "Kahraman ırkıma bir gül… ne bu şiddet bu celâl?\n"+
        "Sana olmaz dökülen kanlarımız sonra helâl,\n"+
        "Hakkıdır, Hakk’a tapan, milletimin istiklâl.\n"+
        "\n"+
        "Ben ezelden beridir hür yaşadım, hür yaşarım.\n"+
        "Hangi çılgın bana zincir vuracakmış? Şaşarım!\n"+
        "Kükremiş sel gibiyim; bendimi çiğner, aşarım;\n"+
        "Yırtarım dağları, enginlere sığmam, taşarım.\n"+
        "\n"+
        "Garb’ın âfâkını sarmışsa çelik zırhlı duvar;\n"+
        "Benim iman dolu göğsüm gibi serhaddim var.\n"+
        "Ulusun, korkma! Nasıl böyle bir îmânı boğar,\n"+
        "\"Medeniyet!\" dediğin tek dişi kalmış canavar?\n"+
        "\n"+
        "Arkadaş! Yurduma alçakları uğratma sakın;\n"+
        "Siper et gövdeni, dursun bu hayâsızca akın.\n"+
        "Doğacaktır sana va’dettiği günler Hakk’ın…\n"+
        "Kim bilir, belki yarın… belki yarından da yakın.\n"+
        "\n"+
        "Bastığın yerleri \"toprak!\" diyerek geçme, tanı!\n"+
        "Düşün altındaki binlerce kefensiz yatanı.\n"+
        "Sen şehîd oğlusun, incitme, yazıktır atanı;\n"+
        "Verme, dünyâları alsan da, bu cennet vatanı.\n"+
        "\n"+
        "Kim bu cennet vatanın uğruna olmaz ki fedâ?\n"+
        "Şühedâ fışkıracak, toprağı sıksan şühedâ!\n"+
        "Cânı, cânânı, bütün varımı alsın da Hudâ,\n"+
        "Etmesin tek vatanımdan beni dünyâda cüdâ.\n"+
        "\n"+
        "Ruhumun senden, İlâhî, şudur ancak emeli:\n"+
        "Değmesin ma’bedimin göğsüne nâ-mahrem eli!\n"+
        "Bu ezanlar-ki şehâdetleri dînin temeli\n"+
        "Ebedî yurdumun üstünde benim inlemeli\n"+
        "\n"+
        "O zaman vecd ile bin secde eder –varsa- taşım;\n"+
        "Her cerîhamdan, İlâhî, boşanıp kanlı yaşım,\n"+
        "Fışkırır ruh-i mücerred gibi yerden na’şım;\n"+
        "O zaman yükselerek Arş’a değer, belki başım.\n"+
        "\n"+
        "Dalgalan sen de şafaklar gibi ey şanlı hilâl;\n"+
        "Olsun artık dökülen kanlarımın hepsi helâl.\n"+
        "Ebediyen sana yok, ırkıma yok izmihlâl:\n"+
        "Hakkıdır, hür yaşamış bayrağımın hürriyet;\n"+
        "Hakkıdır, Hakk’a tapan milletimin istiklâl!\";"
    }

    getIstiklalMarsiContent = () => (new Content(this.contentName, this.author, this.content, 1))
    

}


