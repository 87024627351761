import React, { Component } from "react";
import CommonMethodsForExercises from "./CommonMethodsForExercises";
import { Input, InputGroup, InputGroupAddon, Button, CardImg } from "reactstrap";
import style from "../../style.module.css"
import { useParams } from "react-router";
import { BsThreeDotsVertical } from "react-icons/bs";


export default class Write extends Component {

  constructor(props) {
    super(props);
    this.input_event_target = null;
    console.log("let go: ", this.props.currentUserLearningActivity.currentVerseIds)

    this.common = new CommonMethodsForExercises(
      this.props.currentUserLearningActivity.currentContent.content_text,
      parseInt(this.props.match.params.difLevel),
      3,
      this.props.currentUserLearningActivity.currentVerseIds
    );
  }

  componentDidMount() {
    this.common.setProps(this.props)
    this.props.onGameStart(this.common.editTextCount, this.common.hint_count);
  }

  handleChange = (event) => {
    event.target.value = this.common.clearPunctuation(event.target.value)

    this.input_event_target = event.target;

    let word_without_punctuation = this.common.clearPunctuation(this.common.selected_words[this.common.first_empty_mtcv])
    let word_length = word_without_punctuation.length;

    if (event.target.value.trim().length === word_length) {
      this.common.check(
        event.target.value.trim().toLowerCase(),
        word_without_punctuation.toLowerCase(),
        0,0,0,
        event
      );
    }
  };

  render() {
    return (
      <div>
        {this.common.renderVerse()}

        <div className={style.container_with_centered_items}>
          <div>
            <Input
              autoFocus="autofocus"
              placeholder="Cevap"
              ref={(input) => {this.nameInput = input; }} 
              type="alphanumeric"
              filter
              autoComplete="off"
              onChange={this.handleChange}/>
          </div>
        </div>
        
        {this.common.renderHintButton(this.input_event_target)}
      </div>
    );
  }
}
