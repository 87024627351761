import React, { Component } from "react";
import { Container } from "reactstrap";
import appstyle from "../../App.css";
import Navi from "../navi/Navi";
import Global from "../common/Global"
import firebase from "firebase";
import { Switch, Route } from "react-router-dom";
import NotFound from "../common/NotFound";
import Starter from "../common/Starter";
import CommonMethods from "../common/CommonMethods";
import Content from "../Models/Content";
import Category from "../categoryPage/CategoryRoute"

export default class App extends Component {
  state = {
    currentCategory: "",
    currentCategoryId: "",
    items: [],

    currentUserLearningActivity: {
      currentContent: {},
      currentVerseIds: [],
      content_route_name: ""
    },
  };

  constructor() {
    super()
    let storage_str = window.localStorage.getItem("ezberleriz");
    const valuesToSave = JSON.parse(storage_str);

    if(storage_str){
      this.state = {
        currentCategory: valuesToSave.currentCategory,
        currentCategoryId: valuesToSave.currentCategoryId,
        currentUserLearningActivity: valuesToSave.currentUserLearningActivity,
        items: valuesToSave.items,
      }
    }
    
  }


  componentDidUpdate() {
    // componentDidMount'ta valuesToSave'e koyulma sırasına göre alınıyor.
    let currentCategory = this.state.currentCategory;
    let currentCategoryId = this.state.currentCategoryId;
    let currentUserLearningActivity = this.state.currentUserLearningActivity;
    let items = this.state.items;
    const valuesToSave = {
      currentCategory,
      currentCategoryId,
      currentUserLearningActivity,
      items,
    };
    console.log("valuesToSave: ", valuesToSave);
    window.localStorage.setItem("ezberleriz", JSON.stringify(valuesToSave));
  }

  setCurrentUserLearningActivity = (currentUserLearningActivity) => {
    this.setState({
      currentUserLearningActivity,
    });
  };

  changeCategory = (category) => {
    this.setState({
      currentCategory: category.categoryName,
      currentCategoryId: category.categoryId,
    });

    if (parseInt(category.categoryId) !== Global.ISTIKLAL_MARSI_ID && parseInt(category.categoryId) !== Global.KENDIN_EKLE_ID)
      this.getContents(category.categoryId); // Kendin Ekle ve İst.Marşı sonrası.
  };

  getFirebaseChildName = (section_id) => {
    switch(section_id){
      case Global.DUALAR_ID: return "dualar_content";
      case Global.SIIRLER_ID: return "siirler_content";
      case Global.MARSLAR_ID: return "marslar_content";
      default: return "";
    }
  };

  getUserContents = () => {
    let user_contents;

    if (localStorage.getItem("user_contents") === null) {
      user_contents = [];
    } else {
      user_contents = JSON.parse(localStorage.getItem("user_contents"));
    }

    this.setState({
      items: user_contents.reverse(),
    });
  };

  getContentsFromFirebase = (categoryId) => {
    // Firebase'den alınma işlemi yapılacak.
    let childName = this.getFirebaseChildName(categoryId);
    const rootRef = firebase.database().ref();
    const contentsRef = rootRef.child(childName);
    contentsRef.on("value", (snap) => {
      let items = [];
      snap.forEach((item) => {
        let itemObj = item.val();
        let contentObject = new Content(
          itemObj.name,
          itemObj.writer,
          itemObj.content,
          categoryId
        );
        items.push(contentObject);
      });
      this.setState({
        items: items,
      });
    });
  };

  getContents = (categoryId) => {
    if (categoryId === Global.SON_AKTIVITELER_ID) {
      // Sizin Ekledikleriniz
      this.getUserContents();
    } else {
      this.getContentsFromFirebase(categoryId);
    }
  };

  render() {
    this.route_name = CommonMethods.getCategoryRouteName(
      this.state.currentCategoryId
    );

    return (
      <div className="fill-window">
          <Navi
            changeCategory={this.changeCategory}
            setCurrentUserLearningActivity={this.setCurrentUserLearningActivity}
          />

          <Switch>
            <Route
              exact path="/" render={(props) => (
                <Starter
                  {...props}
                  changeCategory={this.changeCategory}
                  setCurrentUserLearningActivity={
                    this.setCurrentUserLearningActivity
                  }
                />
              )}
            ></Route>
            <Route path="/:currentCategory" render={(props) => (
                <Category
                  {...props}
                  currentCategory={this.state.currentCategory}
                  currentCategoryId={this.state.currentCategoryId}
                  items={this.state.items}
                  setCurrentUserLearningActivity={this.setCurrentUserLearningActivity}
                  currentUserLearningActivity={this.state.currentUserLearningActivity}
                />
              )}
            ></Route>   

            <Route path="/" component={NotFound}></Route>
          </Switch>
      </div>
    );
  }
}
