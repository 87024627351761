import React, { Component } from "react";
import MultipleChoice from "./MultipleChoice";
import TapFirst from "./TapFirst";
import Write from "./Write";
import { Switch, Route } from "react-router-dom";
import ResultPage from "./ResultPage"


export default class PracticeTypes extends Component {
	

    renderResultPage = () => (
        <Route
            path={
            "/" +
            this.props.route_name +
            this.props.content_route_name +
            "/:verse_id_from_url/practice/:practice_type/:difLevel"
            }
            render={(props) => (
                <ResultPage
                    {...props}
                    game_stats={this.props.game_stats}
                    route_name={this.props.route_name}
                    content_route_name={this.props.content_route_name}
                    resetPractice={this.props.resetPractice}
                />
            )}
        ></Route>
    )

    renderPractices = () => (
        <Switch>
            <Route
                path={
                "/" +
                this.props.route_name +
                this.props.content_route_name +
                "/:verse_id_from_url/practice/multiple-choice/:difLevel"
                }
                render={(props) => (
                <MultipleChoice
                    {...props}
                    currentUserLearningActivity={
                        this.props.currentUserLearningActivity
                    }
                    game_stats={this.props.game_stats}
                    setRemainingCount={this.props.setRemainingCount}
                    onRightAnswer={this.props.onRightAnswer}
                    onWrongAnswer={this.props.onWrongAnswer}
                    finishExercise={this.props.finishExercise}
                    onGameStart={this.props.onGameStart}
                    onHintUse={this.props.onHintUse}
                    option_states={this.props.option_states}
                />
                )}
            ></Route>
            <Route
                path={
                "/" +
                this.props.route_name +
                this.props.content_route_name +
                "/:verse_id_from_url/practice/tap-first/:difLevel"
                }
                render={(props) => (
                <TapFirst
                    {...props}
                    currentUserLearningActivity={
                        this.props.currentUserLearningActivity
                    }
                    game_stats={this.props.game_stats}
                    setRemainingCount={this.props.setRemainingCount}
                    onRightAnswer={this.props.onRightAnswer}
                    onWrongAnswer={this.props.onWrongAnswer}
                    finishExercise={this.props.finishExercise}
                    onGameStart={this.props.onGameStart}
                    onHintUse={this.props.onHintUse}
                />
                )}
            ></Route>
            <Route
            path={
                "/" +
                this.props.route_name +
                this.props.content_route_name +
                "/:verse_id_from_url/practice/write/:difLevel"
            }
            render={(props) => (
                <Write
                {...props}
                currentUserLearningActivity={
                    this.props.currentUserLearningActivity
                }
                game_stats={this.props.game_stats}
                setRemainingCount={this.props.setRemainingCount}
                onRightAnswer={this.props.onRightAnswer}
                onWrongAnswer={this.props.onWrongAnswer}
                finishExercise={this.props.finishExercise}
                onGameStart={this.props.onGameStart}
                onHintUse={this.props.onHintUse}
                />
            )}
            ></Route>

        </Switch>

    )


    render = () => {
        return (
            this.props.game_state === 3 ? 
                    this.renderResultPage():
                    this.renderPractices()
        )
    }
    

}