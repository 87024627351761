import slugify from "slugify";
import Global from "./Global"
import istiklal_marsi from "../contents/istiklal_marsi"

export default class CommonMethods{

    static constructor(){

    }

    static setCategory(section_id, props){
        let categoryName = Global.CATEGORY_NAMES[section_id]
        let categoryId = section_id
    
        props.changeCategory({
            categoryName,
            categoryId
        })

        if(parseInt(section_id) === Global.ISTIKLAL_MARSI_ID){
            let ist = new istiklal_marsi()
            let contentObject = ist.getIstiklalMarsiContent()
            console.log("contentObject: ", contentObject)

            props.setCurrentUserLearningActivity({
                currentContent: contentObject,   // Bunu {} içerisinde verseydim tekrar currentContent.contentObject.content_text yapmak gerekiyordu.
                content_route_name: "",
                currentVerseIds: [1],
                ...props.currentUserLearningActivity
            })
        }
      }

    static getCategoryRouteName(section_id){
        return  Global.CATEGORY_ROUTE_NAMES[section_id];
    }

    static makeSlug(contentName){
        return slugify(contentName, {
            replacement: '-',  // replace spaces with replacement character, defaults to `-`
            remove: /[*+~.()'"!:@]/g, // remove characters that match regex, defaults to `undefined`
            lower: true,      // convert to lower case, defaults to `false`
            strict: false,     // strip special characters except replacement, defaults to `false`
            locale: 'tr'       // language code of the locale to use
          })
    }

}








