import React, { Component } from 'react'
import { ListGroup, ListGroupItem, Button } from "reactstrap";
import CommonMethodsForExercises from '../practice/CommonMethodsForExercises';
import {Link, Route, Switch} from "react-router-dom"
import style from "../../style.module.css"
import Practice from "../practice/Practice"
import classNames from 'classnames'
import KeyboardEventHandler from 'react-keyboard-event-handler';



export default class Read extends Component {

    state={
        current_verse_id: "",
        verse: ""
    }

    constructor(props){
        super (props);

        this.content_verses = []
        this.content_verses = CommonMethodsForExercises
        .seperateThePoemIntoVerses(this.props.currentUserLearningActivity.currentContent.content_text)

        /*this.state.verse_ids.push(this.props.currentUserLearningActivity.currentVerseIds)*/

        /*for(let i = 0; i < this.state.verse_ids.length; i++){
            this.verse += this.content_verses[this.state.verse_ids[i]] + (this.state.verse_ids.length > 1 ? "\n\n" : "");
        }*/
        // Daha sonra birden fazla kıtalı hallere girersem değiştirilir. Şu an tek string.

        // Html için \n kullanmayın diyorlar, başka şeyler önermişler br kullanın diyorlar olmaz ki öyle de,
        //benim hiçbir şeyime uymaz o. En iyisi \n'e göre ayırıp ListGroup'a eklemek.
        this.content_route_name = this.props.currentUserLearningActivity.content_route_name;

        this.initial_verse_id = parseInt(this.props.match.params.verse_id_from_url)
        let upper_bound = this.content_verses.length - 1
        if(this.initial_verse_id > upper_bound) this.initial_verse_id = upper_bound
        else if(this.initial_verse_id < 0) this.initial_verse_id = 0;
        console.log("here you go", this.initial_verse_id);
    }
    componentDidMount() {
        this.setState({current_verse_id: this.initial_verse_id})
    }

    renderVerse = () => {
        if(this.state.verse === ""){
            if(!this.initial_verse_id && this.initial_verse_id !== 0){ // 0'da giriyormuş buraya ilginç ben sadece undefined ise girer gibi düşündüm.
                this.initial_verse_id = 
                    this.props.currentUserLearningActivity.currentVerseIds === undefined ?
                    1 :
                    parseInt(this.props.currentUserLearningActivity.currentVerseIds[0]);
            }
            
            this.setState({verse: this.content_verses[this.initial_verse_id]})
            this.props.setCurrentUserLearningActivity({
                currentVerseIds: [this.initial_verse_id],
                ...this.props.currentUserLearningActivity
            })
        }

        this.verse_lines = this.state.verse.split("\n");

        return (
            <div className={style.verse_container_div}>
                <ListGroup className={style.verse_container}>
                    {
                        this.verse_lines.map((line,index) => (
                            <div>
                            <ListGroupItem style={{borderWidth: 0}} key={index}>
                                {line}
                            </ListGroupItem>
                            </div>
                        ))
                    }
                </ListGroup>
            </div>
        )
    }
    
    renderVerseNavigationButtons = () => {
        if(this.content_verses.length !== 2){
            return (<div className={classNames(style.container_with_centered_items2, style.center_full_screen)}>
                 {this.content_verses.map((verse,index) => (  // 0(T), 1, 2 
                        <Button 
                            className={style.verse_nav_button}
                            color={this.state.current_verse_id === index ? "warning" : "secondary"}
                            key={index} onClick={() => this.handleVerse(index)}>
                            {index === 0 ? "T" : index}
                        </Button>
                       
                    
                    /*<Link to={"/"+this.props.route_name+
                            (this.content_route_name === "" ? "":  "/"+this.content_route_name)
                            +"/"+index}>
                                <Button 
                                    className={style.verse_nav_button}
                                    color={this.state.current_verse_id === index ? "warning" : "secondary"}
                                    key={index} onClick={() => this.handleVerse(index)}>
                                    {index === 0 ? "T" : index}
                                </Button>
                    </Link>*/
                 ))}
             </div>)
        } else{
            return null;
        }

    }      

    handleVerse = (index) => {
        this.props.setCurrentUserLearningActivity({
            ...this.props.currentUserLearningActivity,
            currentVerseIds: [index]
        })
        this.setState({current_verse_id: index})
        this.setState({verse: this.content_verses[index]})
    }

    renderReadPage = () => (
        <div>
            {this.renderVerse()}
            <div className={classNames(style.scrolling_wrapper)}>
                 {/* container_with_centered_items yaparsam overflow'dan kaybediyorum.*/ }
                {this.renderVerseNavigationButtons()}
            </div>
            <div className={style.container_with_centered_items}>
                <Link 
                    style={{textDecoration: 'none'}} 
                    to={"/"+this.props.route_name+
                    (this.content_route_name === "" ? "":  "/"+this.content_route_name)
                    +"/"+(this.state.current_verse_id ? this.state.current_verse_id : 1)
                    +"/practice"}>
                        <Button color="primary" size="lg">Şimdi Ezberle</Button>
                </Link>
            </div>
        </div>
    )

    onKeyPressed(key){
        if(key === 'left' && this.content_verses.length > 2){
            let new_verse_id = this.state.current_verse_id === 0 ? 0 : (this.state.current_verse_id - 1);

            this.handleVerse(new_verse_id)

            // Push - replace yapmayalım şimdilik. Düşündüğümü yapamadım.
            // Önemli: replace yaptık. Yoksa geriye bastığında diğer verselere gidip duruyor.
            /*this.props.history.replace({
                pathname: "/"+this.props.route_name+
                      (this.content_route_name === "" ? "":  "/"+this.content_route_name)
                        +"/"+new_verse_id
            });*/
            // replace(path, [state]) - (function) Replaces the current entry on the history stack
        } 
        if(key === 'right' && this.content_verses.length > 2){
            let new_verse_id = this.state.current_verse_id === (this.content_verses.length - 1) ? 
                            (this.content_verses.length - 1) : (this.state.current_verse_id + 1);

            this.handleVerse(new_verse_id)

            /*this.props.history.replace({
                pathname: "/"+this.props.route_name+
                (this.content_route_name === "" ? "":  "/"+this.content_route_name)
                +"/"+new_verse_id
            });*/
        } 

    }

    render() {
        return (
            <div>
                <KeyboardEventHandler handleKeys={['left', 'right']} onKeyEvent={(key) => this.onKeyPressed(key)} />

                {this.renderReadPage()}
            </div>
        )
    }
}
