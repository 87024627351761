import React, { Component } from 'react'
import {Button, Container, Row, Col, CardImg, CardBody, CardTitle} from 'reactstrap'
import Global from "./Global"
import style from '../../style.module.css';
import Card from 'reactstrap/lib/Card';
import classNames from 'classnames'
import {Link} from "react-router-dom"
import CommonMethods from './CommonMethods';


export default class Starter extends Component {

    getCardTitle(section_id){
        return Global.CATEGORY_NAMES[section_id];
    }

    getImage(section_id){
        switch (section_id){
            case Global.ISTIKLAL_MARSI_ID: return require('../../images/turkish_flag.png');
            case Global.DUALAR_ID: return require('../../images/dualar.png');
            case Global.SIIRLER_ID: return require('../../images/siirler.png');
            case Global.MARSLAR_ID: return require('../../images/marslar.png');
            case Global.KENDIN_EKLE_ID: return require('../../images/sarkilar.png');
            case Global.SON_AKTIVITELER_ID: return require('../../images/tutorial_journey.jpg');
            default: return require('../../images/tutorial_journey.jpg');
        }
    }

    getClassNames(section_id){
        switch (section_id){
            case Global.ISTIKLAL_MARSI_ID: return classNames(style.main_screen_box, style.istiklal);
            case Global.DUALAR_ID: return classNames(style.main_screen_box, style.dualar);
            case Global.SIIRLER_ID: return classNames(style.main_screen_box, style.siirler);
            case Global.MARSLAR_ID: return classNames(style.main_screen_box, style.marslar);
            case Global.KENDIN_EKLE_ID: return classNames(style.main_screen_box, style.sarkilar);
            case Global.SON_AKTIVITELER_ID: return classNames(style.main_screen_box, style.kendinekle);
            default: return classNames(style.main_screen_box, style.istiklal);
        }
    }

    render() {

        return (
            <div className={style.starterCardsContainer}>
                {/*this.renderMainScreenCard(Global.SON_AKTIVITELER_ID)*/}
                {this.renderMainScreenCard(Global.ISTIKLAL_MARSI_ID)}
                {this.renderMainScreenCard(Global.DUALAR_ID)}
                {this.renderMainScreenCard(Global.SIIRLER_ID)}
                {this.renderMainScreenCard(Global.MARSLAR_ID)}
                {/*this.renderMainScreenCard(Global.KENDIN_EKLE_ID)*/}
            </div>
        )
    }

    renderMainScreenCard = (section_id) => (
        <div>
        <Link style={{textDecoration: 'none'}} to={"/"+CommonMethods.getCategoryRouteName(section_id)}>
            <Card className={this.getClassNames(section_id)} onClick={() => CommonMethods.setCategory(section_id, this.props)}>
                <CardImg className={style.main_screen_image} src={this.getImage(section_id)}/>
                <CardTitle tag="h3">{this.getCardTitle(section_id)}</CardTitle>
            </Card>
        </Link>
        </div>
    );

}
