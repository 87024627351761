import React from "react";
import RemoveMethods from "../common/RemoveMethods";
import { ListGroup, ListGroupItem, CardImg } from "reactstrap";
import { BsCircleFill } from "react-icons/bs";
import style from "../../style.module.css";

export default class CommonMethodsForExercises {

  setProps(props) {
    this.props = props;
  }

  constructor(str, difficulty_level, practice_id, verse_ids) {
    this.str = str;
    this.difficulty_level = difficulty_level;
    this.practice_id = practice_id;
    this.verse_ids = verse_ids;

    this.content_verses = [];
    this.content_verses = CommonMethodsForExercises.seperateThePoemIntoVerses(
      this.str
    );

    //let verse_ids = [];
    //verse_ids.push(this.props.currentUserLearningActivity.currentVerseIds);
    this.verse = "";

    for (let i = 0; i < verse_ids.length; i++) {
      this.verse +=
        this.content_verses[verse_ids[i]] +
        (verse_ids.length > 1 ? "\n\n" : "");
    }

    this.row_count = 0;
    this.hint_count = 0;
    this.str_split2 = this.splitStanzaIntoWords();
    this.str_split2_original = this.splitStanzaIntoWords();
    this.str_split2_nochange = [];   /// ??? bir karmaşa var ne bunlar, original ve nochange aynı değil mi :)

    if(parseInt(this.verse_ids[0]) === 0 || this.verse_ids.length > 1){
      this.makeChangesForMultipleVerses();
    }

    this.rnd = [];
    this.rnd = this.replaceWithAsterisk(
      this.row_count,
      this.str_split2,
      this.difficulty_level
    );
    this.str_split2.forEach((str1) => {
      this.str_split2_nochange.push(str1);
    });

    this.selected_words = [];
    this.selected_words_coordinates = [];
    this.str_split2.forEach((str1, index1) => {
      str1.forEach((word, index2) => {
        if (word.indexOf("@") !== -1) {
          this.selected_words.push(this.str_split2_original[index1][index2]);
          this.selected_words_coordinates.push([index1, index2]);
        }
      });
    });

    this.verse_word_count = this.findWordCount(this.str_split2);
    this.editTextCount = this.findEditTextCount(this.verse_word_count);

    this.existent_et_indexes = [];
    this.non_existent_ets_count = 0;
    let u = 0,
      a = 0;
    for (let i = 0; i < this.editTextCount; i++) {
      this.existent_et_indexes[a] = u++;

      if (this.rnd[i] !== -1) {
        a++;
      } else {
        this.non_existent_ets_count++;
      }
    }
    console.log("et: ", this.editTextCount, this.non_existent_ets_count)
    this.editTextCount = this.editTextCount - this.non_existent_ets_count;

    this.hint_count = parseInt(this.editTextCount / 8 + 1);

    this.first_empty_mtcv = 0;
    this.consecutive_wrong_answer_count = 0;

    // Multiple Choice
    //Options
    this.tur = 0;
    this.flag = 0;
    this.count2 = 0;
    this.count3 = 0;
    this.random = [];
    this.option_texts = ["", "", "", ""];
    this.option_text_coordinates = [-1, -1, -1, -1];
    this.init_options();

    //TapFirst
    this.input_char = "";

    this.asked_word_index = 0;

    this.index = 0;

  }

  makeChangesForMultipleVerses = () => {
    let empty_rows_count = this.findEmptyRowIndexes();
    console.log("empty_rows_count", empty_rows_count)
    this.changeWordArraysAndRowCount(empty_rows_count);
  }

   findEmptyRowIndexes = () => {
    // Find Empty Rows Count and Empty Row Indexes
      let empty_rows_count = 0;

      // str2 rowlara ayrılmış hali, str ve newStr (str_split2 ve str_split2_original) rowlara ve kelimelere ayrılmış hali
      let p = 0;
      
      for(let i = 0; i < this.str2.length; i++){
          if(this.str2[i].toString().trim() === ""){
              empty_rows_count++;
          }
      }
      this.empty_row_indexes = []
      for(let i = 0; i < this.str2.length; i++){
          if(this.str2[i].trim() === ""){
              this.empty_row_indexes[p++] = i;
          }
      }
      console.log("empty_row_indexes: ", this.empty_row_indexes)

      return empty_rows_count;
  }
  changeWordArraysAndRowCount(empty_rows_count){
    let str_non_empty_rows = []  // row_count - empty_rows_count büyüklüğünde

    let m = 0;
    for(let j = 0; j < this.row_count; j++){
        if(this.str2[j].toString().trim() !== "")
            str_non_empty_rows[m++] = this.str2[j];
    }

    for(let k = 0; k < this.row_count - empty_rows_count; k++){
      this.str_split2[k] = str_non_empty_rows[k].split(" ");
    }
    for(let k = 0; k < this.row_count - empty_rows_count; k++){
      this.str_split2_original[k] = str_non_empty_rows[k].split(" ");
    }

    this.row_count = this.row_count - empty_rows_count;
}

  replaceWithAsterisk(rowNumber, newStr, difficulty_level) {
    let rnd = [];

    switch (
      difficulty_level //Burada newStr veriyorum, o değişiyor.
    ) {
      case 1:
        rnd = RemoveMethods.removeForEasyMode(newStr, rowNumber);
        break;
      case 2:
        rnd = RemoveMethods.removeForMediumMode(newStr, rowNumber);
        break;
      case 3:
        rnd = RemoveMethods.removeForHardMode(newStr, rowNumber);
        break;
      case 4:
        rnd = RemoveMethods.removeForLevel4(newStr, rowNumber);
        break;
      case 5:
        rnd = RemoveMethods.removeForVeryHardMode(newStr, rowNumber);
        break;
      default:
        break;
    }

    return rnd;
  }

  splitStanzaIntoWords() {
    let str = [];
    let str2 = [];

    this.str2 = str2 = this.verse.split("\n");
    this.row_count = str2.length;

    for (let i = 0; i < this.row_count; i++) {
      //Baştaki boşlukları sil
      while (str2[i].length !== 0 && str2[i].charAt(0) === " ") {
        str2[i] = this.charRemoveAt(str2[i], 0);
      }
    }

    //Sondaki boşluklar? Bunlar da sorun çıkarıyor, ortadaki boşlukların üstüne aldım, aşağıdakine outofboundsexception verdiriyor.
    for (let i = 0; i < this.row_count; i++) {
      while (
        str2[i].length !== 0 &&
        str2[i].charAt(str2[i].length - 1) === " "
      ) {
        str2[i] = this.charRemoveAt(str2[i], str2[i].length - 1);
      }
    }

    for (let i = 0; i < this.row_count; i++) {
      //Satır içinde birden fazla yan yana boşluk varsa teke indir.
      for (let k = 0; k < str2[i].length; k++) {
        while (
          str2[i].length !== 0 &&
          str2[i].charAt(k) === " " &&
          str2[i].charAt(k + 1) === " "
        ) {
          str2[i] = this.charRemoveAt(str2[i], k + 1);
        }
      }
    }

    for (let j = 0; j < this.row_count; j++) {
      str[j] = str2[j].split(" ");
    }

    return str;
  }

  static seperateThePoemIntoVerses = (all_poem_text) => {
    let rows_corrected = [];
    let rows_corrected2 = [];
    let verses = [];
    let rows = [];
    let allRows_count = 0,
      aboveEmptyRows_count = 0,
      extraEmptyRows_count = 0,
      emptyRows_count = 0;

    rows = all_poem_text.split("\n");

    let k = 0;
    while (rows[k].trim() === "") {
      //En başında boşluklar varsa temizliyoruz.
      //ilk dolu olanı bul
      k++;
    } //k burada baştaki boş satır sayısını verir.

    allRows_count = rows.length; //emptyRows included  TRUE

    aboveEmptyRows_count = k;
    // new String[allRows_count - aboveEmptyRows_count];
    //Buradaki k değerini tutmalıyım, aşağıda lazım oluyor, burada aboveEmptyRows_count = k;

    for (
      let i = 0;
      i < allRows_count - aboveEmptyRows_count && k < rows.length;
      i++, k++
    ) {
      rows_corrected[i] = rows[k];
    }

    //Üstüste boşlukları sayalım... Yani bir boşluk var onu almıyorum, ondan sonra boşluk varsa/lar onları alıyorum.

    for (let i = 0; i < rows_corrected.length; i++) {
      if (rows_corrected[i].trim() === "") {
        let p = i;

        while (rows_corrected[p + 1].trim() === "") {
          extraEmptyRows_count++;
          p++;
          i++;
        }
      }
    }

    //let rows_corrected2 = [];
    //new String[allRows_count - aboveEmptyRows_count - extraEmptyRows_count];

    let u = 0;
    for (
      let i = 0;
      u < allRows_count - aboveEmptyRows_count - extraEmptyRows_count &&
      i < rows_corrected.length;
      u++, i++
    ) {
      rows_corrected2[u] = rows_corrected[i];

      if (rows_corrected[i].trim() === "") {
        while (rows_corrected[i + 1].trim() === "") {
          i++;
        }
      }
    }

    //rows_corrected : Şimdilik aklıma gelenler
    //1. En baştaki boşlukları temizlemek
    //2. Sondaki boşlukları zaten almadığını görüyorum
    //3. Birden fazla boşluk varsa onu bir tane olarak almak
    //4. rows_corrected2 yapıyorum ama bunun sonundaki boş olanları silmek lazım.
    //5. Bomboş verirse hata veriyor, hemen düzeltelim.

    for (let i = 0; i < allRows_count; i++) {
      if (rows[i].trim() === "") {
        emptyRows_count++;
      }
    }

    //Seperate into verses  0.indeksi tümü olsun istiyorum diğer indekslerde kıtalar.
    //Dur bakalım buradaki emptyRows şöyle... Baştaki boşluklar ve extralar çıkarılmış haline 1 eklenerek bulunuyor
    //emptyRowsCount her şey dahil emptyRows... Çıkarıyoruz above'dakileri, çıkarıyoruz extraları. Extraları doğru yaptığına emin olmalıyım.

    verses = [];
    let verses_count =
      emptyRows_count - aboveEmptyRows_count - extraEmptyRows_count + 1 + 1;
    //new String[(emptyRows_count-aboveEmptyRows_count-extraEmptyRows_count)+1+1];  //Boş satır sayısının 1 fazlası kadar kıta var.
    //+1 +1 Çünkü 0.indekste tümünü tutuyorum 1 + kıta sayısı
    let v = 1;
    //Boşluklu satırlarla birlikte aldık, olması gereken de bu.

    for (let i = 0; i < verses_count; i++) {
      verses[i] = ""; //Başta null olmasını böyle çözdük.
    }

    for (let i = 0; i < rows_corrected2.length; i++) {
      verses[0] += rows_corrected2[i];
      if (i !== rows_corrected2.length - 1) verses[0] += "\n";

      if (rows_corrected2[i].trim() === "") v++;
      //Boşsa diğer kıtaya geç, tek boş satıra indirdik kıta aralarını.
      else {
        verses[v] += rows_corrected2[i]; //Baştaki boşluklar ve extralar atılmış halde.
        if (
          i !== rows_corrected2.length - 1 &&
          rows_corrected2[i + 1].trim() !== ""
        )
          verses[v] += "\n";
      }
      //Boş değilse ekle, boşsa v'yi 1 artır, diğer satıra geç.
    }

    return verses;
  };

  findWordCount(str) {
    let length = 0;
    for (let i = 0; i < this.row_count; i++) {
      for (let j = 0; j < str[i].length; j++) {
        length++;
      }
    }

    return length;
  }

  findEditTextCount(length) {
    //el2 length
    let elSize;
    if (this.difficulty_level === 5) elSize = length;
    else if (this.difficulty_level === 4) elSize = length - this.row_count;
    else elSize = this.row_count * this.difficulty_level;

    return elSize;
  }

  charRemoveAt(str, p) {
    return str.substring(0, p) + str.substring(p + 1);
  }

  getScoreChange = (consecutive_wrong_answer_count) => {
    let score_change = 0;
    if (consecutive_wrong_answer_count === 0) score_change += 10;
    else if (consecutive_wrong_answer_count === 1) score_change += 5;
    else if (consecutive_wrong_answer_count === 2) score_change += 2;
    else score_change += 0; // 4.basışında buluyor demek, puan yok.

    consecutive_wrong_answer_count = 0; // Doğru cevabı verdiği için 0lıyoruz.

    return [score_change, consecutive_wrong_answer_count];
  };

  getCurrentRow = () => {
    let row = 0;
    let lowerbound = 0,
      upperbound1 = this.str_split2[0].length - 1,
      upperbound2 = this.str_split2[0].length;

    switch (
      this.difficulty_level //row'u bulmak bu kadar kolay değil.
    ) {
      case 1:
        row = this.first_empty_mtcv;
        break;
      case 2:
        row = parseInt(this.existent_et_indexes[this.first_empty_mtcv] / 2);
        break;
      case 3:
        row = parseInt(this.existent_et_indexes[this.first_empty_mtcv] / 3);
        break;
      case 4:
        //4.seviyede 1.satırda kaç tane kelime var? str[0].verse_word_count() - 1 kadar.
        //O zaman first_empty_mtcv + 1'e bakacağız. str[i].verse_word_count()-1lerden hangisini aşıyorsa o rowdadır. Hiçbirini aşmıyorsa ilk satırdadır.
        //Zaten 0 olarak başlattığımız için değişmiyorsa 0da kalacak.
        for (let i = 0; i < this.row_count; i++) {
          lowerbound += this.str_split2[i].length - 1;
          if (i !== this.row_count - 1)
            upperbound1 += this.str_split2[i + 1].length - 1;
          if (
            this.first_empty_mtcv + 1 > lowerbound &&
            this.first_empty_mtcv + 1 <= upperbound1
          ) {
            //Dikkat hangi ikisinin arasındaysa.
            row = i + 1;
          }
        }
        break;
      case 5:
        //5.seviyede 1.satırda kaç tane kelime var? str[0].verse_word_count() kadar.
        for (let i = 0; i < this.row_count; i++) {
          lowerbound += this.str_split2[i].length;
          if (i !== this.row_count - 1)
            upperbound2 += this.str_split2[i + 1].length;
          if (
            this.first_empty_mtcv + 1 > lowerbound &&
            this.first_empty_mtcv + 1 <= upperbound2
          ) {
            //Dikkat hangi ikisinin arasındaysa.
            row = i + 1;
          }
        }
        break;
      default:
        break;
    }

    return row;
  };

  findFirstEmptyWordInRow = (current_row) => {
    let aboveSelectedWords_count = 0,
      first_empty_mtcv_in_row = 0;
    switch (this.difficulty_level) {
      case 1:
        first_empty_mtcv_in_row = 1;
        break; //Önemli : first_empty_mtcv_in_row 1'den başlıyor.
      case 2:
        first_empty_mtcv_in_row =
          (this.existent_et_indexes[this.first_empty_mtcv] % 2) + 1;
        break;
      case 3:
        first_empty_mtcv_in_row =
          (this.existent_et_indexes[this.first_empty_mtcv] % 3) + 1;
        break;
      case 4:
        for (let t = 0; t < current_row; t++) {
          aboveSelectedWords_count += this.str_split2[t].length - 1;
        }
        first_empty_mtcv_in_row =
          this.first_empty_mtcv + 1 - aboveSelectedWords_count;
        break;
      case 5:
        for (let t = 0; t < current_row; t++) {
          aboveSelectedWords_count += this.str_split2[t].length;
        }
        first_empty_mtcv_in_row =
          this.first_empty_mtcv + 1 - aboveSelectedWords_count;
        break;
      default:
        break;
    }

    return first_empty_mtcv_in_row;
  };

  showCurrentRowUpdated = (current_row, first_empty_mtcv_in_row) => {
    // Bulunduğu satırın yazı rengini gri yapar, arka planı seçilmişten çıkarır. TODO
    // (boolean newRowFlag, boolean lastWordOfRow Şimdilik almıyorum bu parametreleri

    // str_split2'de değişiklik yapayım. str_split2_original'dan alınabilir o kelime.
    // current_row'da first_empty_mtcv_in_row'a kadar al original'dan.

    for (let i = 0; i <= current_row; i++) {
      if (i === current_row) {
        let temp = this.str_split2_nochange[i];
        let new_temp = [];
        let k = 0;

        for (let j = 0; j < temp.length; j++) {
          if (temp[j].indexOf("@") !== -1) k++;

          if (temp[j].indexOf("@") !== -1 && k > first_empty_mtcv_in_row) {
            new_temp.push(this.str_split2[i][j]);
          } else {
            new_temp.push(this.str_split2_original[i][j]);
          }
        }

        this.str_split2.splice(i, 1, new_temp);
      } else {
        //current_row öncesi
        this.str_split2.splice(i, 1, this.str_split2_original[i]);
      }
    }
  };

  getHint = (input_event_target) => {
    if (this.hint_count !== 0) {
      this.hint_count--; //Öncesinde yapıyorum. Burada check'e bu gitmiş olmuyor.

      let p = 0;
      if (this.practice_id === 1) {
        for (let k = 0; k < 4; k++) {
          if (
            this.option_texts[k] === this.selected_words[this.first_empty_mtcv]
          )
            p = k;
        }
      }

      this.check("", "", p, "", "");
    } else {
      alert("İpucu hakkınız dolmuştur.");
    }

    if (this.practice_id === 3 && input_event_target) {
      input_event_target.value = "";
      input_event_target.focus();
    }
  };

  init_options = () => {
    let random = [];
    if (this.editTextCount === 1) random = [0];
    else if (this.editTextCount === 2) random = [0, 1];
    else if (this.editTextCount === 3) random = [0, 1, 2];
    else random = [0, 1, 2, 3];

    //Shuffle
    for (let i = random.length - 1; i > 0; i--) {
      let index = parseInt(Math.random() * (i + 1));

      //Simple swap
      let temp = random[index];
      random[index] = random[i];
      random[i] = temp;
    }

    for (let i = 0; i < this.option_texts.length; i++) {
      let str = this.selected_words[i];

      this.option_texts[random[i]] = str;
      if (this.practice_id === 1)
        this.option_text_coordinates[
          random[i]
        ] = this.selected_words_coordinates[i]; //Koordinatı dizi olarak dönecek [x, y]
    }
  };

  isPunctuation = (ch) => {
    return !(
      ch.length === 1 &&
      ch !== " " &&
      (ch.match(/[a-zA-Z]/) ||
        Number.isInteger(parseInt(ch)) ||
        this.isTurkishCharacter(ch))
    );
  };
  isTurkishCharacter = (ch) => {
    if (
      ch === "ç" ||
      ch === "ğ" ||
      ch === "ı" ||
      ch === "ö" ||
      ch === "ş" ||
      ch === "ü" ||
      ch === "Ç" ||
      ch === "Ğ" ||
      ch === "İ" ||
      ch === "Ö" ||
      ch === "Ş" ||
      ch === "Ü" ||
      ch === "â" ||
      ch === "Â" ||
      ch === "î" ||
      ch === "Î"
    )
      return true;

    return false;
  };

  clearPunctuation = (word) => {
    for (let k = word.length - 1; k >= 0; k--) {
      if (this.isPunctuation(word.charAt(k))) {
        word = this.charRemoveAt(word, k);
      }
    }
    return word;
  };

  checkEqualsForTR = (clicked_word, answer_word) => {
    let difference = 0;

    let anotherString = answer_word;
    let n = clicked_word.length;
    if (n === anotherString.length) {
      let i = 0;
      while (n-- !== 0) {
        if (clicked_word.charAt(i) !== anotherString.charAt(i)) {
          difference = 1;
        }

        if (difference === 1) {
          if (
            (clicked_word.charAt(i) === "i" &&
              anotherString.charAt(i) === "ı") ||
            (clicked_word.charAt(i) === "o" &&
              anotherString.charAt(i) === "ö") ||
            (clicked_word.charAt(i) === "ç" &&
              anotherString.charAt(i) === "c") ||
            (clicked_word.charAt(i) === "u" &&
              anotherString.charAt(i) === "ü") ||
            (clicked_word.charAt(i) === "ğ" &&
              anotherString.charAt(i) === "g") ||
            (clicked_word.charAt(i) === "s" &&
              anotherString.charAt(i) === "ş") ||
            (clicked_word.charAt(i) === "ı" &&
              anotherString.charAt(i) === "i") ||
            (clicked_word.charAt(i) === "ö" &&
              anotherString.charAt(i) === "o") ||
            (clicked_word.charAt(i) === "c" &&
              anotherString.charAt(i) === "ç") ||
            (clicked_word.charAt(i) === "ü" &&
              anotherString.charAt(i) === "u") ||
            (clicked_word.charAt(i) === "g" &&
              anotherString.charAt(i) === "ğ") ||
            (clicked_word.charAt(i) === "ş" &&
              anotherString.charAt(i) === "s") ||
            (clicked_word.charAt(i) === "â" &&
              anotherString.charAt(i) === "a") ||
            (clicked_word.charAt(i) === "a" &&
              anotherString.charAt(i) === "â") ||
            (clicked_word.charAt(i) === "î" &&
              anotherString.charAt(i) === "i") ||
            (clicked_word.charAt(i) === "i" && anotherString.charAt(i) === "î")
          )
            difference = 0;
        }

        if (difference === 1) return false;

        i++;
      }

      return true; //False döneceği varsa zaten içeride döner.
    }

    return false; //String değilse dönecek, bizimkiler zaten string gerçi gerek yok.
  };

  check = (
    clicked_word,
    answer_word,
    p,
    clicked_option_coordinate,
    right_answer_coordinate,
    event
  ) => {
    if (this.practice_id === 2) this.input_char = clicked_word;

    if (event) event.target.value = "";

    if (this.checkEqualsForTR(clicked_word, answer_word)) {
      this.onRightAnswer(
        p,
        clicked_option_coordinate,
        right_answer_coordinate,
        answer_word
      );
    } else {
      this.onWrongAnswer(p);
    }

    return this.str_split2;
  };

  checkCoordinatesEqual = (
    clicked_option_coordinate,
    right_answer_coordinate
  ) => {
    return (
      clicked_option_coordinate[0] === right_answer_coordinate[0] &&
      clicked_option_coordinate[1] === right_answer_coordinate[1]
    );
  };
  getNewOptionIndex = (p, answer_word, right_answer_coordinate) => {
    for (let i = 0; i < this.option_texts.length; i++) {
      if (
        this.option_texts[i] === answer_word &&
        this.option_text_coordinates[i][0] === right_answer_coordinate[0] &&
        this.option_text_coordinates[i][1] === right_answer_coordinate[1]
      ) {
        p = i;
        break;
      }
    }
    return p;
  };
  changeClickedOption = (p) => {
    if (this.first_empty_mtcv + 4 < this.editTextCount) {
      if (this.first_empty_mtcv % 4 === 0) {
        this.tur++;

        this.random = [0, 1, 2, 3];

        if (this.editTextCount - 4 * this.tur === 1) {
          this.random[0] = 0;
          this.random[1] = 0;
          this.random[2] = 0;
          this.random[3] = 0;
        } else if (this.editTextCount - 4 * this.tur === 2) {
          this.flag = 2;
        } else if (this.editTextCount - 4 * this.tur === 3) {
          this.flag = 3;
        } else {
          for (let j = this.random.length - 1; j > 0; j--) {
            let index = parseInt(Math.random() * (j + 1));
            //Simple swap
            let temp = this.random[index];
            this.random[index] = this.random[j];
            this.random[j] = temp;
          }
        }
      }

      if (this.flag === 2) {
        this.count2++;
        let x = -1;
        if (this.count2 === 1) x = 0;
        if (this.count2 === 2) x = 1;

        this.option_texts[p] = this.selected_words[4 * this.tur + x];
        this.option_text_coordinates[p] = this.selected_words_coordinates[
          4 * this.tur + x
        ];
      } else if (this.flag === 3) {
        this.count3++;

        let x = -1;
        if (this.count3 === 1) x = 0;
        if (this.count3 === 2) x = 1;
        if (this.count3 === 3) x = 2;

        this.option_texts[p] = this.selected_words[4 * this.tur + x];
        this.option_text_coordinates[p] = this.selected_words_coordinates[
          4 * this.tur + x
        ];
      } else {
        this.option_texts[p] = this.selected_words[
          4 * this.tur + this.random[p]
        ];
        this.option_text_coordinates[p] = this.selected_words_coordinates[
          4 * this.tur + this.random[p]
        ];
      }
    } else {
      this.option_texts[p] = "";
    }
  };

  onRightAnswer = (
    p,
    clicked_option_coordinate,
    right_answer_coordinate,
    answer_word
  ) => {
    if (this.practice_id === 1) {
      let coordinatesEqual = this.checkCoordinatesEqual(
        clicked_option_coordinate,
        right_answer_coordinate
      );
      if (!coordinatesEqual) {
        p = this.getNewOptionIndex(p, answer_word, right_answer_coordinate);
      }
    }

    let score_change = 0;
    [score_change, this.consecutive_wrong_answer_count] = this.getScoreChange(
      this.consecutive_wrong_answer_count
    );

    this.props.onRightAnswer(score_change, this.hint_count);
    // Şu anki first_empty_mtcv'ye göre mevcut satırı bulur.
    let current_row = this.getCurrentRow(
      this.str_split2,
      this.first_empty_mtcv,
      this.existent_et_indexes
    );
    // Doğru yaptığımız için buradayız, bir sonraki sorulacak kelimeyi işaretlemeliyiz.
    let first_empty_mtcv_in_row = this.findFirstEmptyWordInRow(current_row);

    // Mevcut satırı doğru yaptığı kelimeyi açarak ekrana yazdırır.
    this.showCurrentRowUpdated(current_row, first_empty_mtcv_in_row);
    // Bu da yeni şık belirleme algoritmam. Şıklardaki tıklanan doğru Mtcv'yi değiştiriyorum. Son 4e gelene kadar değişecek, sonra yok olmaya başlayacak.
    if (this.practice_id === 1) this.changeClickedOption(p);

    this.first_empty_mtcv++;

    if (this.first_empty_mtcv === this.editTextCount) {
      // Sonuna gelinmişse.
      this.props.finishExercise();
    } else {
      let current_row2 = -1;
      if (this.first_empty_mtcv < this.editTextCount) {
        current_row2 = this.getCurrentRow(
          this.str_split2,
          this.first_empty_mtcv,
          this.existent_et_indexes
        );
      }
      first_empty_mtcv_in_row = 0;

      if (
        current_row2 !== current_row &&
        current_row2 + 1 <= this.row_count &&
        current_row2 !== 0
      ) {
        // Rowlar değişmişse
        this.showCurrentRowUpdated(current_row2, first_empty_mtcv_in_row);
      }
    }
  };

  onWrongAnswer = (p) => {
    this.props.onWrongAnswer(-10, p);

    this.consecutive_wrong_answer_count++;
  };

  getRowsShowingNow = () => {
    return this.str_split2.filter((row, index) => index === this.getCurrentRow() || index === this.getCurrentRow()+1)
  }

  renderVerse = () => {
    this.asked_word_index = 0;

    return (
      <ListGroup className={style.practice_verse_container}>
        {this.getRowsShowingNow().map((str1, row_index) => (
          <ListGroupItem
            style={{ backgroundColor: "#004878" }}
            className={style.practice_verse_lines}
            key={row_index}>
            <div className="list-item">
            {str1.map((word, index2) =>
                word.indexOf("@") === -1 ? (
                  row_index === 0 ? (
                    <h4
                      style={{ display: "inline", margin: 2, color: "#FFFFFF" }}
                    >
                      {word + " "}
                    </h4>
                  ) : (
                    <h4
                      style={{ display: "inline", margin: 2, color: "#2282BC" }}
                    >
                      {word + " "}
                    </h4>
                  )
                ) : this.asked_word_index++ === 0 ? (
                  <h4 style={{ display: "inline", margin: 2 }}>
                    <BsCircleFill style={{ fill: "orange" }} />{" "}
                  </h4>
                ) : (
                  <h4 style={{ display: "inline", margin: 2 }}>
                    <BsCircleFill style={{ fill: "black" }} />{" "}
                  </h4>
                )
              )}
               {//row_index === 0 ? this.leaveSpaceBetweenVerses(this.getCurrentRow()) : null
                // Şimdilik gerek yok gibi düşündüm boşluk bırakmayı. 
               }
            </div>
          </ListGroupItem>
        ))}
      </ListGroup>
    );
  };

  leaveSpaceBetweenVerses = (i) => {
    if(parseInt(this.verse_ids[0]) === 0 || this.verse_ids.length > 1){
      let emptyFlag = 0;
      for(let a = 0; a < this.empty_row_indexes.length; a++){
          if(i + (a + 1) === this.empty_row_indexes[a]){  //4, 9, 14  indexler... Bu halinde i'nin 3, 7, 11 olduğu durumlarda bu boşluk yıldız konulmalı.
              emptyFlag = 1;
              break;
          }
      }
      if(emptyFlag === 1){  //Buradayız.
        return (<div style={{height: 20}}></div>)
      }    
    }   

    return null;
  }
  renderHintButton = (input_event_target) => {
    return (
      <div className={style.container_with_centered_items}>
        <div>
          <CardImg
            className={
              this.hint_count !== 0
                ? style.hint_button_active
                : style.hint_button_passive
            }
            onClick={() => this.getHint(input_event_target)}
            src={require("../../images/lightbulb.png")}
          />
        </div>
      </div>
    );
  };
}
