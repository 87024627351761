import React, { Component } from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import style from "../../style.module.css";

export default class ChoosePractice extends Component {

    constructor(props){
        super(props);
    }

    getPracticeRouteName = (practice_type) => {
        switch(practice_type){
          case 1: return "multiple-choice"
          case 2: return "tap-first"
          case 3: return "write"
          default: return ""
        }
      }

    renderPracticeChoiceButton = (color, practice_type, text) => (
        <div className={style.container_with_centered_items}>
            <Link
                onClick={() => this.props.onChoosePractice(practice_type)}
                to={
                    "/" +
                    this.props.route_name +
                    this.props.content_route_name  +
                    "/"+parseInt(this.props.match.params.verse_id_from_url)+
                    "/practice/"+this.getPracticeRouteName(practice_type)
                }
            >
                <div><Button className={style.practice_choice_button} color={color} size="lg">
                    {text}
                    </Button>
                </div>
            </Link>
        </div>
    )

    render = () => (
        <div className={style.center}>
            {this.renderPracticeChoiceButton("primary", 1, "Çoktan Seçmeli")}
            {this.renderPracticeChoiceButton("info", 2, "İlk Harflerle Doldur")}
            {this.renderPracticeChoiceButton("warning", 3, "Kendin Yaz")}
        </div>
    )

}