import React, { Component } from "react";
import CommonMethodsForExercises from "./CommonMethodsForExercises";
import {CardImg} from "reactstrap";
import KeyboardEventHandler from 'react-keyboard-event-handler';
import style from "../../style.module.css"



export default class TapFirst extends Component {
  constructor(props) {
    super(props);
    console.log("let go: ", this.props.currentUserLearningActivity.currentVerseIds)

    this.common = new CommonMethodsForExercises(
      this.props.currentUserLearningActivity.currentContent.content_text,
      parseInt(this.props.match.params.difLevel),
      2,
      this.props.currentUserLearningActivity.currentVerseIds
    );
  }

  componentDidMount() {
    this.common.setProps(this.props)
    this.props.onGameStart(this.common.editTextCount, this.common.hint_count);
  }

  onKeyPressed(key, e){
    //console.log(key)  //  key: \
    //console.log(e.key)  // e.key: ç

    if (this.common.isPunctuation(this.common.selected_words[this.common.first_empty_mtcv].charAt(0))){
      this.common.selected_words[this.common.first_empty_mtcv] = 
      this.common.charRemoveAt(this.common.selected_words[this.common.first_empty_mtcv], 0);
    }

    if(key === 'space') this.common.getHint()
    else this.common.check(e.key.toLowerCase(), this.common.selected_words[this.common.first_empty_mtcv][0].toLowerCase());
  }

  render() {
    return (
      <div>
        <KeyboardEventHandler handleKeys={['all']} onKeyEvent={(key, e) => this.onKeyPressed(key, e)} />
        {/* alphanumeric yaparsak i, ş vs basmıyor. İng. karakterler basıyor sadece. */}

        {this.common.renderVerse()}
        {this.common.renderHintButton()}
      </div>
    );
  }
}
