import React, { Component } from "react";
import style from '../../style.module.css';
import StarRatings from 'react-star-ratings';
import {Link} from 'react-router-dom'
import {Button} from 'reactstrap'
import classNames from 'classnames'

export default class ResultPage extends Component {

    constructor(props){
        super(props);

        this.success_level = this.findSuccessLevel();
        this.current_verse_id = this.props.match.params.verse_id_from_url;
    }

    findSuccessLevel = () => {
        if(this.props.game_stats.health <= 0) return -1;

        let editTextCount = this.props.game_stats.hit_count + this.props.game_stats.fail_count;
        let maximum_possible_score = editTextCount * 10;
        let success_level, score = this.props.game_stats.score;

        if(score >= parseInt(maximum_possible_score * 0.95)) success_level = 5;
        else if(score >= parseInt(maximum_possible_score * 0.90)) success_level = 4;
        else if(score >= parseInt(maximum_possible_score * 0.80)) success_level = 3;
        else if(score >= parseInt(maximum_possible_score * 0.70)) success_level = 2;
        else success_level = 1;

        return success_level;
    }
    getResultText = (success_level) => {
        switch (success_level){
            case 5: return "Harika!";
            case 4: return "Mükemmele bir adım kala!";
            case 3: return "Daha iyi olabilir!";
            case 2: return "Çalışmamız lazım!";
            case 1: return "Bu pek olmadı...";
            default: return "Bu sefer olmadı!";
        }
    }

    renderBackButton = () => (
        <div className={style.container_with_centered_items}>
            <Link to={"/" +
                this.props.route_name + "/" +
                this.props.content_route_name + 
                this.current_verse_id}>
                <Button color="primary" size="lg" className={classNames(style.container_with_centered_items, style.practice_choice_button)}>
                    İçeriğe Dön
                </Button>
            </Link>
        </div>
    )
    renderNewExerciseButton = () => (
        <div className={style.container_with_centered_items}>
            <Link 
                onClick={() => this.props.resetPractice()}
                to={"/" +
                this.props.route_name + "/" +
                this.props.content_route_name  +    
                this.current_verse_id + 
                "/practice"}>
                <Button color="primary" size="lg" className={classNames(style.container_with_centered_items, style.practice_choice_button)}>
                    Yeni Alıştırma 
                </Button>
            </Link>
        </div>
    )

    render = () => (
        <div className={style.center}>
            <div className={classNames(style.container_with_centered_items, style.resultText)}>
                Skor: {this.props.game_stats.score}
            </div>

            <div className={classNames(style.container_with_centered_items, style.resultText)} style={{fontSize: "25px"}}>
                {this.getResultText(this.success_level)}
            </div>

            <div className={style.container_with_centered_items}>
                <StarRatings
                    rating={this.success_level}
                    starRatedColor="#FFB400"
                    numberOfStars={5}
                    name='rating'
                />
            </div>
            

            {this.renderBackButton()}

            {this.renderNewExerciseButton()}
            
        </div>
    )

}