import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import AddYourContent from "../contents/AddYourContent"
import NotFound from "../common/NotFound";
import ContentList from "../contents/ContentList"
import ContentRoute from "./ContentRoute"


export default class Category extends Component {

    constructor(props){
        super(props);
    }

    render(){
        {this.route_name = this.props.match.params.currentCategory}
        return (
        <div>
            <Switch>
                <Route path="/kendin_ekle" render={(props) => (
                    <AddYourContent
                    {...props}
                    setCurrentUserLearningActivity={this.setCurrentUserLearningActivity}
                    />
                )}
                ></Route>
                
                <Route path={["/istiklal_marsi"+"/:verse_id_from_url", "/istiklal_marsi",
                        "/"+this.route_name+"/:content_name/:verse_id_from_url","/"+this.route_name+"/:content_name",
                        "/istiklal_marsi/:verse_id_from_url/practice", 
                        "/"+this.route_name+"/:content_route_name/:verse_id_from_url/practice"]} 
                        render={(props) => (
                    <ContentRoute
                        {...props}
                        currentCategory={this.props.currentCategory} currentCategoryId={this.props.currentCategoryId}
                        route_name={this.route_name} currentUserLearningActivity={this.props.currentUserLearningActivity} 
                        setCurrentUserLearningActivity={this.props.setCurrentUserLearningActivity}/>
                    )}></Route>

                <Route exact path="/:currentCategory" render={(props) => (
                    <ContentList
                    {...props}
                    currentCategory={this.props.currentCategory}
                    currentCategoryId={this.props.currentCategoryId}
                    items={this.props.items}
                    setCurrentUserLearningActivity={this.props.setCurrentUserLearningActivity}
                    currentUserLearningActivity={this.props.currentUserLearningActivity}
                    />
                )}
                ></Route>   
                <Route path="/" component={NotFound}></Route>
            </Switch>
        </div>);
    }

}
