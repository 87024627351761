import React, { Component } from "react";
import CommonMethodsForExercises from "./CommonMethodsForExercises";
import { Row, Col, Container } from "reactstrap";
import style from "../../style.module.css"
import KeyboardEventHandler from 'react-keyboard-event-handler';
import classNames from 'classnames'


export default class MultipleChoice extends Component {
  
  constructor(props) {
    super(props);
    this.common = new CommonMethodsForExercises(
      this.props.currentUserLearningActivity.currentContent.content_text,
      parseInt(this.props.match.params.difLevel),
      1,
      this.props.currentUserLearningActivity.currentVerseIds
    );
  }

  componentDidMount() {
    this.common.setProps(this.props)
    this.props.onGameStart(this.common.editTextCount, this.common.hint_count);
  }

  optionClick = (optionText, i) => {
    let str1 = optionText;
    let str2 = this.common.selected_words[this.common.first_empty_mtcv];
    let str1_coordinate = this.common.option_text_coordinates[i];
    let str2_coordinate = this.common.selected_words_coordinates[this.common.first_empty_mtcv];

    //this.str_split2 = 
    this.common.check(str1, str2, i, str1_coordinate, str2_coordinate);
  };

  renderOptions = () => (
    <Container>
      <Row className={style.container_options}>
          <Col xs="6" className="row justify-content-end">
            <div
              className={this.props.option_states[0] === 0 ? classNames(style.choice, style.choice_default) 
                : classNames(style.choice, style.choice_wrong)}
              style={this.common.option_texts[0] === "" ? {visibility: "hidden"} : {visibility: "visible"}}
              onClick={() => this.optionClick(this.common.option_texts[0], 0)}>
              {this.common.option_texts[0] === "" ? "a" : this.common.option_texts[0]}
            </div>
          </Col> 
          <Col xs="6" className="row justify-content-start">
            <div
              className={this.props.option_states[1] === 0 ? classNames(style.choice, style.choice_default) 
                : classNames(style.choice, style.choice_wrong)}
              style={this.common.option_texts[1] === "" ? {visibility: "hidden"} : {visibility: "visible"}}
              onClick={() => this.optionClick(this.common.option_texts[1], 1)}
            >
              {this.common.option_texts[1] === "" ? "b" : this.common.option_texts[1]}
            </div>
        </Col>
      </Row>
      <Row className={style.container_options}>
          <Col xs="6" className="row justify-content-end">
          <div
             className={this.props.option_states[2] === 0 ? classNames(style.choice, style.choice_default) 
              : classNames(style.choice, style.choice_wrong)}
            style={this.common.option_texts[2] === "" ? {visibility: "hidden"} : {visibility: "visible"}}
            onClick={() => this.optionClick(this.common.option_texts[2], 2)}
          >
            {this.common.option_texts[2] === "" ? "c" : this.common.option_texts[2]}
          </div>
        </Col>

        <Col xs="6" className="row justify-content-start">
        <div
            className={this.props.option_states[3] === 0 ? classNames(style.choice, style.choice_default) 
            : classNames(style.choice, style.choice_wrong)}
            style={this.common.option_texts[3] === "" ? {visibility: "hidden"} : {visibility: "visible"}}
            onClick={() => this.optionClick(this.common.option_texts[3], 3)}
        >
          {this.common.option_texts[3] === "" ? "d" : this.common.option_texts[3]}
        </div>
      </Col>
      </Row>
    </Container>
  );

  onKeyPressed(key){
      if(key === '1') this.optionClick(this.common.option_texts[0], 0)
      if(key === '2') this.optionClick(this.common.option_texts[1], 1)
      if(key === '3') this.optionClick(this.common.option_texts[2], 2)
      if(key === '4') this.optionClick(this.common.option_texts[3], 3)
      if(key === 'space') this.common.getHint()
  }

  render() {
    return (
      <div>
        <KeyboardEventHandler handleKeys={['numeric', 'space']} onKeyEvent={(key) => this.onKeyPressed(key)} />

        {this.common.renderVerse()}
        {this.renderOptions()}
        {this.common.renderHintButton()}
                    
      </div>
    );
  }
}
